import React, { useContext, useEffect, useState } from "react";
import styled from "styled-components";
import { rgba } from "polished";
import makeRequest from "../pages/api/config-request";

import { postApiNew, urlList } from "../pages/api/config.js";
import { NavLink as RouterNavLink, withRouter, Link } from "react-router-dom";
// import { darken } from "polished";

import PerfectScrollbar from "react-perfect-scrollbar";
import "../vendor/perfect-scrollbar.css";
import { Box, Modal } from "@mui/material";
import { spacing } from "@material-ui/system";
import './style.css'
import './styleSidebar.css'
import { isIOS } from 'react-device-detect';
import {
  Grid,
  Chip,
  ListItem,
  ListItemText,
  Drawer as MuiDrawer,
  Divider as MuiDivider,
  List as MuiList,
  Typography,
  Button
} from "@material-ui/core";

import { ExpandLess, ExpandMore } from "@material-ui/icons";

import routes from "../routes/index";
import Loader from 'react-loader-spinner';

import { Layers } from "react-feather";
import { PlayListContext } from "../Session";
import { useTranslation } from "react-i18next";
import { useAlert } from "react-alert";


const style = {
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  width: '90%',
  height: 'auto',
  backgroundColor: '#181818',
  border: '2px solid #000',
  borderRadius: '1rem',
  p: 4,
  padding: '10px'

};




const Scrollbar = styled(PerfectScrollbar)`
  background-color: #000000;
  border-right: 1px solid rgba(0, 0, 0, 0.12);
`;

const List = styled(MuiList)`
  background-color: #000000 
  ;
`;
const Divider = styled(MuiDivider)(spacing);

const Items = styled.div`
width : 90% ;
margin-left: auto;
margin-right : auto;
  padding-top: ${props => props.theme.spacing(2.5)}px;
  padding-bottom: ${props => props.theme.spacing(2.5)}px;
`;


const Category = styled(ListItem)`
  padding-top: ${props => props.theme.spacing(3)}px;
  padding-bottom: ${props => props.theme.spacing(3)}px;
  padding-left: ${props => props.theme.spacing(6)}px;
  padding-right: ${props => props.theme.spacing(5)}px;
  font-weight: ${props => props.theme.typography.fontWeightRegular};

  svg {
    color: ${props => props.theme.sidebar.color};
    font-size: 20px;
    width: 20px;
    height: 20px;
    opacity: 0.5;
  }

  &:hover {
    background: rgba(0, 0, 0, 0.08);
  }

  &.${props => props.activeClassName} {
    background-color: #282828;
  
    border-radius: 5px;
    span {
      color: ${props => props.theme.sidebar.color};
    }
  }
`;

const CategoryText = styled(ListItemText)`
  margin: 0;
  span {
    color: ${props => props.theme.sidebar.color};
    font-size: ${props => props.theme.typography.body1.fontSize}px;
    font-weight: ${props => props.theme.typography.fontWeightRegular};
    padding: 0 ${props => props.theme.spacing(4)}px;
  }
`;

const Flag = styled.img`
  // border-radius: 50%;
  width: 22px;
  height: 22px;
`;

const CategoryIconLess = styled(ExpandLess)`
  color: ${props => rgba(props.theme.sidebar.color, 0.5)};
`;

const CategoryIconMore = styled(ExpandMore)`
  color: ${props => rgba(props.theme.sidebar.color, 0.5)};
`;


const LinkBadge = styled(Chip)`
  font-size: 11px;
  font-weight: ${props => props.theme.typography.fontWeightBold};
  height: 20px;
  position: absolute;
  right: 12px;
  top: 8px;
  background: ${props => props.theme.sidebar.badge.background};

  span.MuiChip-label,
  span.MuiChip-label:hover {
    cursor: pointer;
    color: ${props => props.theme.sidebar.badge.color};
    padding-left: ${props => props.theme.spacing(2)}px;
    padding-right: ${props => props.theme.spacing(2)}px;
  }
`;

const CategoryBadge = styled(LinkBadge)`
  top: 12px;
`;


const SidebarFooter = styled.div`
  background-color: black !important;
  padding: ${props => props.theme.spacing(3)}px
    ${props => props.theme.spacing(4)}px;
  border-right: 1px solid rgba(0, 0, 0, 0.12);
`;



function SidebarCategory({
  name,
  icon,
  classes,
  isOpen,
  isCollapsable,
  badge,
  ...rest
}) {
  const { t } = useTranslation();

  return (
    <Category {...rest}>
      <Flag src={icon} alt="Devices" />
      <CategoryText>{t(name)}</CategoryText>
      {isCollapsable ? (
        isOpen ? (
          <CategoryIconMore />
        ) : (
          <CategoryIconLess />
        )
      ) : null}
      {badge ? <CategoryBadge label={badge} /> : ""}
    </Category>
  );
}



function SidebarMobile(props) {

  const alert = useAlert();
  const { t, i18n } = useTranslation();
  const playList = useContext(PlayListContext)

  const [language, setLanguage] = useState(playList.language);
  const [userPlaylist, setUserPlaylist] = useState(null)
  const { classes, staticContext, functionHanldeOpen, loading, contextLoadSideBar, history, ...other } = props;
  const users = localStorage.getItem('name') ? localStorage.getItem('name') : '1'
  const phone = localStorage.getItem('phoneNumber') ? localStorage.getItem('phoneNumber') : '1'
  const token = localStorage.getItem('token') ? localStorage.getItem('token') : '1'
  const userId = localStorage.getItem('userId') ? localStorage.getItem('userId') : '1'
  const [openModal, setOpenModal] = useState(false)
  const redirectLink = (link) => {
    if (link == '#') {
      window.open('https://www.autofarmer.me/', '_blank');
    }
  }

  useEffect(async () => {
    showData()

  }, [loading, contextLoadSideBar])


  const handleLangChange = (type) => {
    playList.setLanguage(type)
    i18n.changeLanguage(type);
  };

  const NavLink = React.forwardRef((props, ref) => (
    <RouterNavLink innerRef={ref} {...props} />
  ));

  const showData = async () => {
    const param = {
      timestamp: new Date().getTime(),
      page: 0,
      size: 10,

    }
    const headers = {
      userId: userId,
      token: token,
      "Accept-language": playList.language,
    }
    await makeRequest("get", urlList().URL_PERSONAL.OWNER_PLAYLIST, param, headers, {
    })
      .then(({ data }) => {
        if (data.data) {
          setUserPlaylist(data.data)


        }

      })
      .catch((err) => {
        alert.error(t('unexpected-error') + ": " + err)

      });


  }
  const redirectPlaylist = id => {
    history.push({
      pathname: '/userPlaylist',
      search: '?playlist=' + id
    });
  }
  const onclickRedirect = async e => {
    history.push({ pathname: '/' });
  }

  return (



    <div className="side-bar-main-mobile">


      <div style={{
        height: '100%',

      }}>

        <Scrollbar>
          <List>
            <Items>
              <React.Fragment >

                <SidebarCategory
                  isCollapsable={false}
                  name={!users ? phone : users == '1' ? '' : users}

                  icon={require("../assets/icon/user.png")}

                />


              </React.Fragment>

              <React.Fragment >

                <SidebarCategory
                  isCollapsable={false}
                  name={t('search')}
                  onClick={() => {
                    history.push({ pathname: '/search' });
                  }
                  }
                  icon={require("../assets/search.png")}

                />


              </React.Fragment>
              {routes.map((category, index) =>
              (

                <div>

                  <>
                    {(index == 2 || index == 5) ? <Divider my={6} /> : null}

                    {category.name == 'CreatePlayList' && token != 1 ?
                      ''
                      : category.show ? (
                        <React.Fragment key={index}>
                          <SidebarCategory
                            isCollapsable={false}
                            name={category.id}
                            to={category.path}
                            onClick={() => redirectLink(category.path)}
                            activeClassName={category.path !== '#' ? 'active' : ''}
                            component={NavLink}
                            icon={category.icon}
                            badge={category.badge}
                            exact
                          />

                        </React.Fragment>
                      )
                        :
                        ''
                    }


                  </>
                </div>
              )
              )
              }


              <React.Fragment >

                <SidebarCategory
                  isCollapsable={false}
                  name={t('download-app')}
                  onClick={() => {
                    if (isIOS) {
                      window.open('itms-apps://apps.apple.com/id/app/halojasa/id1492671277');
                    }
                    else
                      window.open('http://play.google.com/store/apps');
                  }
                  }
                  icon={require("../assets/icon/download.png")}

                />

                <SidebarCategory
                  isCollapsable={false}
                  name={t('language')}
                  onClick={() => {
                    setOpenModal(true);
                  }}
                  icon={require("../assets/language.png")}

                />
                
                <SidebarCategory
                  isCollapsable={false}
                  name={t('upgrade-to-vip')}
                  onClick={() => {
                    if (token == '1') {
                      // window.location.href = 'https://uid.com.la/auth/signin?redirectUri=' + 'http://umusic.ringme.vn/' + '&state=umusic&clientId=3cf85bc2-fd4a-48fc-b85b-c2fd4a38fc04&isFromWeb=true'
                      window.location.href = 'https://uid.com.la/auth/signin?redirectUri=' + 'http://api.umusic.la/' + '&state=umusic&clientId=3cf85bc2-fd4a-48fc-b85b-c2fd4a38fc04&isFromWeb=true'
                    } else {
                      window.location.href = 'http://api.umusic.la/umusic-service/v1/pack_mps/res?msisdn=' + phone + '&lang=' + playList.language;
                    }
                  }}
                  icon={require("../assets/icon/vip.png")}

                />


              </React.Fragment>

              {token != '1' ?
                <React.Fragment >

                  <SidebarCategory
                    isCollapsable={false}
                    name={t('logout')}
                    onClick={() => {
                      localStorage.setItem('userId', '');
                      localStorage.setItem('phoneNumber', '');
                      localStorage.setItem('email', '');
                      localStorage.setItem('token', '');
                      localStorage.setItem('userName', '');
                      localStorage.setItem('avatar', '');
                      localStorage.setItem('tokenExpired', '');
                      window.location.href = '/'
                      window.location.reload();
                    }}
                    icon={require("../assets/sign-out.png")}

                  />


                </React.Fragment>
                : ''

              }
            </Items>


          </List>


        </Scrollbar >

      </div>

      <Modal
        open={openModal}
        onClose={() => {
          setOpenModal(false)
        }}
        className='modal-language'
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box sx={style}>
          <div className={playList.language == 'lo' ? 'active' : ''} style={{ padding: '10px', width: 'auto', color: 'white', marginBottom: '10px', marginTop: '10px', borderRadius: '1rem' }} onClick={() => {
            handleLangChange('lo')
          }}>
            Lao
          </div>
          <div className={playList.language == 'en' ? 'active' : ''} style={{ padding: '10px', width: 'auto', color: 'white', marginBottom: '10px', borderRadius: '1rem' }} onClick={() => {
            handleLangChange('en')
          }}>
            English
          </div>
          <div className={playList.language == 'vi' ? 'active' : ''} style={{ padding: '10px', width: 'auto', color: 'white', marginBottom: '10px', borderRadius: '1rem' }} onClick={() => {
            handleLangChange('vi')
          }}>
            Vietnamese
          </div>
        </Box>
      </Modal>
    </div>

  );
}


export default withRouter(SidebarMobile);
